.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  flex: 1;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

p {
  margin-top: 0;
}

input[type="file"] {
  display: none;
}
