@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  flex: 1 1;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

p {
  margin-top: 0;
}

input[type="file"] {
  display: none;
}

.navbar {
  align-items: center;
  background-color: #164734;
  min-height: 5vh;
  max-height: 50px;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  min-width: 100%;
}

.navbar .navbar-container {
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 1920px;
  margin: auto;
}

.navbar .navbar-container .logo {
  margin-left: 5vw;
  color: #ffb71b;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 1200px) {
  .navbar .navbar-container .logo {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .navbar .navbar-container .logo {
    font-size: 0.8rem;
  }
}

@media (max-width: 290px) {
  .navbar .navbar-container .logo {
    font-size: 0.6rem;
  }
}

.navbar .navbar-container .nav-Links {
  list-style: none;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5vw;
}

@media (max-width: 640px) {
  .navbar .navbar-container .nav-Links {
    display: none;
  }
}

.navbar .navbar-container .nav-Links .nav-Link {
  color: #ffb71b;
  text-decoration: none;
  padding: 10px 30px;
}

@media (max-width: 1200px) {
  .navbar .navbar-container .nav-Links .nav-Link {
    font-size: 0.8rem;
    padding: 10px 15px;
  }
}

.navbar .navbar-container .NavBar-hamburger {
  display: none;
  width: 50%;
  height: 100%;
}

@media (max-width: 640px) {
  .navbar .navbar-container .NavBar-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.navbar .navbar-container .NavBar-hamburger .NavBar-hamburger-button {
  width: 50px;
  height: 50px;
  background-color: transparent;
  color: #ffb71b;
  margin-right: 10px;
  border: none;
  font-size: 1rem;
  font-weight: 800;
}

.navbar .navbar-container .NavBar-hamburger .NavBar-hamburger-nav-Links {
  list-style: none;
  width: 90%;
  height: 100%;
  margin: auto;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9;
  padding: 0px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-container .NavBar-hamburger .NavBar-hamburger-nav-Links .nav-Link {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffb71b;
  text-decoration: none;
  background-color: #164734;
  font-size: 0.86rem;
  width: 100%;
  height: 40px;
  border: none;
}

.navbar .navbar-container .NavBar-hamburger .NavBar-hamburger-nav-Links .nav-Link:hover {
  color: #164734;
  background-color: #ffb71b;
}
/*# sourceMappingURL=NavBarStyles.css.map */
.alg-800 {
  font-family: "Alegreya Sans", sans-serif;
}

.mav-800 {
  font-family: "Montserrat", sans-serif;
}

.font-openSans {
  font-family: "Open Sans", sans-serif;
}

.font-para-300 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 300;
}

.font-para-400 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 400;
}

.font-para-600 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 600;
}

.font-para-700 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 700;
}

.font-para-900 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 900;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.rem24 {
  font-size: 2.4rem;
}

.rem22 {
  font-size: 2.2rem;
}

.rem2 {
  font-size: 2rem;
}

.rem18 {
  font-size: 1.8rem;
}

.rem16 {
  font-size: 1.6rem;
}

.rem14 {
  font-size: 1.4rem;
}

.rem12 {
  font-size: 1.2rem;
}

.rem1 {
  font-size: 1rem;
}

.rem08 {
  font-size: 0.8rem;
}

.rem06 {
  font-size: 0.6rem;
}

.rem04 {
  font-size: 0.4rem;
}

.r-dec {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.colour-pr {
  color: #164734;
}

.colour-se {
  color: #ffb71b;
}

.colour-bl {
  color: #333330;
}

.colour-wh {
  color: white;
}

.colour-red {
  color: red;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-h-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-5 {
  padding: 5px;
}

.p-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-10 {
  padding: 10px;
}

.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-15 {
  padding: 15px;
}

.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-20 {
  padding: 20px;
}

.p-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.wrapper {
  min-height: 100%;
}

.m-auto {
  margin: auto;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-h-auto {
  margin-right: auto;
  margin-left: auto;
}

.m-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-0 {
  margin: 0;
}

.m-h-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.m-v-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-b-0 {
  margin-top: 0px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-5 {
  margin: 5px;
}

.m-h-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.m-v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-10 {
  margin: 10px;
}

.m-h-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-15 {
  margin: 15px;
}

.m-h-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.m-v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-20 {
  margin: 20px;
}

.m-h-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.m-v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-40 {
  margin: 40px;
}

.m-h-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.m-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-t-250 {
  margin-top: 250px;
}

.clear-button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.hide {
  display: none !important;
}

.seek {
  display: block !important;
}

.seekFlex {
  display: flex !important;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-left {
  justify-content: start;
}

.justify-content-right {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-bottom {
  align-items: flex-end;
}

.flexc {
  display: flex;
  justify-content: center;
}

.flexsa {
  display: flex;
  justify-content: space-around;
}

.flexsb {
  display: flex;
  justify-content: space-between;
}

.flexeven {
  display: flex;
  justify-content: space-evenly;
}

.flexstart {
  display: flex;
  justify-content: start;
}

.flexend {
  display: flex;
  justify-content: end;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.bg-col-pr {
  background-color: #164734;
}

.bg-col-se {
  background-color: #ffb71b;
}

.bg-col-bl {
  background-color: #333330;
}

.shadow {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
}

.rad14 {
  border-radius: 14px;
}

.border-1 {
  border-style: solid;
  border-width: 1px;
}

.border-3 {
  border-style: solid;
  border-width: 3px;
}

.border-pr {
  border-color: #164734;
}

.border-se {
  border-color: #ffb71b;
}

.border-bl {
  border-color: #333330;
}

.border-li {
  border-color: #ccc;
}

.text-l {
  text-align: left;
}

.text-r {
  text-align: right;
}

.text-c {
  text-align: center;
}

.text-j {
  text-align: justify;
}

.col1 {
  -webkit-columns: 1;
          columns: 1;
}

.col2 {
  -webkit-columns: 2;
          columns: 2;
}

@media (max-width: 640px) {
  .col2 {
    -webkit-columns: 1;
            columns: 1;
  }
}

.col3 {
  -webkit-columns: 3;
          columns: 3;
}

@media (max-width: 1200px) {
  .col3 {
    -webkit-columns: 3;
            columns: 3;
  }
}

@media (max-width: 640px) {
  .col3 {
    -webkit-columns: 1;
            columns: 1;
  }
}

.col4 {
  -webkit-columns: 4;
          columns: 4;
}

@media (max-width: 1200px) {
  .col4 {
    -webkit-columns: 3;
            columns: 3;
  }
}

@media (max-width: 640px) {
  .col4 {
    -webkit-columns: 1;
            columns: 1;
  }
}

.col-dotted {
  -webkit-column-rule: 1px dotted #ccc;
          column-rule: 1px dotted #ccc;
  grid-column-gap: 3em;
  -webkit-column-gap: 3em;
          column-gap: 3em;
}

.col-w-250 {
  -webkit-column-width: 250px;
          column-width: 250px;
}

.test-black {
  background-color: black;
}

.test-yellow {
  background-color: yellow;
}

.test-cyan {
  background-color: cyan;
}

.test-green {
  background-color: lightgreen;
}

.test-white {
  background-color: white;
}

.test-gray {
  background-color: lightgray;
}

.test-pink {
  background-color: pink;
}

.test-red {
  background-color: red;
}

.test-blue {
  background-color: blue;
}

.test-widthBig {
  width: 80%;
}

.test-widthSmall {
  width: 20%;
}

.ht-50vh {
  height: 50vh;
}

.ht-200 {
  height: 200px;
}

.ht-175 {
  height: 175px;
}

.ht-150 {
  height: 150px;
}

.ht-125 {
  height: 125px;
}

.ht-100percent {
  height: 100%;
}

.ht-100 {
  height: 100px;
}

.ht-75 {
  height: 75px;
}

.ht-50 {
  height: 50px;
}

.wt-50vw {
  width: 50vw;
}

.wt-200 {
  width: 200px;
}

.wt-175 {
  width: 175px;
}

.wt-150 {
  width: 150px;
}

.wt-125 {
  width: 125px;
}

.wt-100percent {
  width: 100%;
}

.wt-100 {
  width: 100px;
}

.wt-75 {
  width: 75px;
}

.wt-50 {
  width: 50px;
}
/*# sourceMappingURL=Index.css.map */
.DetailedBlog-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.DetailedBlog-container .DetailedBlog-sidebar {
  width: 20vw;
  height: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.DetailedBlog-container .DetailedBlog-sidebar .DetailedBlog-sidebar-title {
  margin-left: 20px;
  width: 65%;
  margin-top: 20vh;
  font-size: 1.36rem;
  word-wrap: break-word;
  font-weight: 800;
  text-align: left;
  color: #333330;
  line-height: 130%;
}

.DetailedBlog-container .DetailedBlog-sidebar .DetailedBlog-sidebar-link {
  width: 47%;
  height: 40px;
  border-radius: 7px;
  margin-left: 20px;
  text-decoration: none;
  cursor: pointer;
}

.DetailedBlog-container .DetailedBlog-sidebar .DetailedBlog-sidebar-link .DetailedBlog-sidebar-button {
  width: 100%;
  height: 100%;
  background-color: #164734;
  color: #ffb71b;
  font-size: 0.7rem;
  font-weight: 800;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-style: none;
  border-radius: 7px;
  padding: 15px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .DetailedBlog-container .DetailedBlog-sidebar {
    display: none;
  }
}

.DetailedBlog-container .DetailedBlog-body {
  margin-left: 0px;
  margin-right: 5%;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .DetailedBlog-container .DetailedBlog-body {
    margin-right: 40px;
    margin-left: 40px;
    width: 100vw;
  }
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body {
    margin-right: 20px;
    margin-left: 20px;
    width: 90vw;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 3vh;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-home-button {
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #164734;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  border: solid 1px #164734;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-home-button:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-home-button .DetailedBlog-home-button-text {
  margin-top: 5px;
  margin-bottom: auto;
  text-decoration: none;
  text-transform: uppercase;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-edit {
  text-transform: uppercase;
  color: #164734;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-edit .DetailedBlog-edit-button {
  font-size: 1.2rem;
  font-weight: 800;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body .DetailedBlog-nav-buttons .DetailedBlog-edit .DetailedBlog-edit-button {
    font-size: 1.2rem;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-title-container .DetailedBlog-title {
  font-size: 2.86rem;
  margin: 0;
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body .DetailedBlog-title-container .DetailedBlog-title {
    margin-top: 15px;
    font-size: 1.4rem;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-blog-profile-container {
  width: 100%;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-share-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-share-container .DetailedBlog-share-label {
  font-size: 1.86rem;
  margin: 0px;
  margin-right: 20px;
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body .DetailedBlog-share-container .DetailedBlog-share-label {
    font-size: 1.4rem;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-chapters-label {
  font-size: 1.86rem;
  margin: 0px;
  margin-top: 25px;
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body .DetailedBlog-chapters-label {
    font-size: 1.4rem;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-summary {
  margin-top: 20px;
  text-align: justify;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-chapters-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 640px) {
  .DetailedBlog-container .DetailedBlog-body .DetailedBlog-chapters-container {
    justify-content: center;
  }
}

.DetailedBlog-container .DetailedBlog-body .DetailedBlog-chapters-container .DetailedBlog-chapter-box {
  width: 300px;
  height: 240px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.BlogProfile-container .BlogProfile-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-image {
  height: 120px;
  aspect-ratio: 1 / 1;
  border-style: solid;
  border-color: #333330;
  border-width: 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.26);
  background-color: #e4e4e4;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-image {
    height: 80px;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info {
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info {
    margin-left: 10px;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-name {
  font-size: 1.86rem;
  margin: 0px;
  font-weight: 800;
  text-align: left;
  text-decoration: none;
  color: #333330;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-name {
    font-size: 0.86rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-date {
  font-size: 1.26rem;
  margin: 0px;
  font-weight: 700;
  text-align: left;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-date {
    font-size: 0.6rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-cities {
  font-size: 0.86rem;
  margin: 0px;
  font-weight: 700;
  text-align: left;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-cities {
    font-size: 0.6rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-flag-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 20px;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-flag-container {
    height: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-flag-container .BlogProfile-flag {
  margin-right: 10px;
  height: 20px;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-profile-info-container .BlogProfile-profile-info .BlogProfile-flag-container .BlogProfile-flag {
    height: 15px;
    margin-right: 5px;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
  font-weight: 800;
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-budget {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
  margin: 0;
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-budget .BlogProfile-budget {
  font-size: 1.86rem;
  margin: 0;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-budget .BlogProfile-budget {
    font-size: 0.86rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-budget .BlogProfile-budget-label {
  font-size: 0.86rem;
  margin: 0;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-budget .BlogProfile-budget-label {
    font-size: 0.6rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
  margin: 0;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal {
    margin-top: 10px;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal .BlogProfile-journal {
  font-size: 1.86rem;
  margin: 0;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal .BlogProfile-journal {
    font-size: 0.86rem;
  }
}

.BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal .BlogProfile-journal-label {
  font-size: 0.86rem;
  margin: 0;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-top .BlogProfile-blog-info-container .BlogProfile-blog-info-journal .BlogProfile-journal-label {
    font-size: 0.6rem;
  }
}

.BlogProfile-container .BlogProfile-bottom {
  height: 40px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-bottom {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
}

.BlogProfile-container .BlogProfile-bottom .BlogProfile-bottom-credits {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 0.56rem;
  text-decoration: none;
  color: #ffb71b;
  margin-right: 10px;
  background-color: #333330;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .BlogProfile-container .BlogProfile-bottom .BlogProfile-bottom-credits {
    padding: 10px 15px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.ChapterThumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.ChapterThumbnail-container .ChapterThumbnail-image {
  width: 100%;
  height: 72%;
  object-fit: cover;
  background-color: #e4e4e4;
}

.ChapterThumbnail-container .ChapterThumbnail-title {
  margin-left: 25px;
  margin-right: 25px;
  height: 28%;
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  word-wrap: break-word;
}

.Chapter-container {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

@media (max-width: 1200px) {
  .Chapter-container {
    width: 85%;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container {
    width: 85%;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.Chapter-container .Chapter-back-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 0px;
  padding-top: 50px;
}

@media (max-width: 1200px) {
  .Chapter-container .Chapter-back-container {
    padding-top: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-back-container {
    margin-top: 20px;
    padding-top: 0px;
  }
}

.Chapter-container .Chapter-back-container .Chapter-back-crumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-back-container .Chapter-back-crumbs {
    font-size: 0.5rem;
  }
}

.Chapter-container .Chapter-back-container .Chapter-back-crumbs .Chapter-back-crumb {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
  margin-right: 5px;
  color: #164734;
}

.Chapter-container .Chapter-back-container .Chapter-back-crumbs .Chapter-back-crumb:hover {
  text-decoration: underline;
}

.Chapter-container .Chapter-back-container .Chapter-back-button {
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #164734;
  width: 78px;
  height: 35px;
  transition: 0.3s;
  border: solid 2px #164734;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-back-container .Chapter-back-button {
    font-size: 0.6rem;
    height: 25px;
    width: 58px;
  }
}

.Chapter-container .Chapter-back-container .Chapter-back-button:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

.Chapter-container .Chapter-back-container .Chapter-back-button .Chapter-back-button-text {
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
}

.Chapter-container .Chapter-blog-profile-container {
  margin-top: 40px;
}

.Chapter-container .Chapter-title {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 3.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-title {
    margin-top: 20px;
  }
}

.Chapter-container .type1 {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 640px) {
  .Chapter-container .type1 {
    margin-top: 20px;
  }
}

.Chapter-container .type1 .type1-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  width: 100%;
}

@media (max-width: 640px) {
  .Chapter-container .type1 .type1-title {
    font-size: 2.46rem;
  }
}

.Chapter-container .type1 .para {
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type1 .para {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type1 .para {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

.Chapter-container .type1-highlighted {
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .Chapter-container .type1-highlighted {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 640px) {
  .Chapter-container .type1-highlighted {
    margin-top: 20px;
  }
}

.Chapter-container .type1-highlighted .type1-title {
  padding-top: 40px;
  margin-bottom: 10px;
  font-size: 3.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-left: 40px;
  margin-right: 40px;
}

@media (max-width: 640px) {
  .Chapter-container .type1-highlighted .type1-title {
    font-size: 1.86rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.Chapter-container .type1-highlighted .para {
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1200px) {
  .Chapter-container .type1-highlighted .para {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type1-highlighted .para {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.Chapter-container .type2-highlighted {
  margin-top: 50px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  width: 100%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted {
    margin-top: 20px;
  }
}

.Chapter-container .type2-highlighted .type2-big {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-big {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big {
    flex-direction: column;
  }
}

.Chapter-container .type2-highlighted .type2-big .type2-big-image {
  width: 70%;
  width: 60%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-big .type2-big-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big .type2-big-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

.Chapter-container .type2-highlighted .type2-big .type2-big-content {
  width: 30%;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-big .type2-big-content {
    width: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big .type2-big-content {
    width: 100%;
    margin-left: 0px;
  }
}

.Chapter-container .type2-highlighted .type2-big .type2-big-content .type2-title {
  margin-bottom: 10px;
  font-size: 2.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big .type2-big-content .type2-title {
    font-size: 2.46rem;
  }
}

.Chapter-container .type2-highlighted .type2-big .type2-big-content .para {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
}

.Chapter-container .type2-highlighted .type2-big .type2-left-content {
  margin-left: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-big .type2-left-content {
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big .type2-left-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2-highlighted .type2-big .type2-right-content {
  margin-right: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-big .type2-right-content {
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-big .type2-right-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2-highlighted .type2-small {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 40px;
  margin-right: 40px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-small {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small {
    flex-direction: column;
  }
}

.Chapter-container .type2-highlighted .type2-small .type2-small-image {
  width: 30%;
  width: 32%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-small .type2-small-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small .type2-small-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

.Chapter-container .type2-highlighted .type2-small .type2-small-content {
  width: 70%;
  width: 60%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-small .type2-small-content {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small .type2-small-content {
    width: 100%;
    margin-left: 0px;
  }
}

.Chapter-container .type2-highlighted .type2-small .type2-small-content .type2-title {
  margin-bottom: 10px;
  font-size: 2.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small .type2-small-content .type2-title {
    font-size: 2.46rem;
  }
}

.Chapter-container .type2-highlighted .type2-small .type2-small-content .para {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
}

.Chapter-container .type2-highlighted .type2-small .type2-left-content {
  margin-left: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-small .type2-left-content {
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small .type2-left-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2-highlighted .type2-small .type2-right-content {
  margin-right: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2-highlighted .type2-small .type2-right-content {
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2-highlighted .type2-small .type2-right-content {
    margin-right: 0px;
  }
}

.Chapter-container .type2 {
  margin-top: 50px;
  width: 100%;
}

@media (max-width: 640px) {
  .Chapter-container .type2 {
    margin-top: 20px;
  }
}

.Chapter-container .type2 .type2-big {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-big {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big {
    flex-direction: column;
  }
}

.Chapter-container .type2 .type2-big .type2-big-image {
  width: 70%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-big .type2-big-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big .type2-big-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

.Chapter-container .type2 .type2-big .type2-big-content {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-big .type2-big-content {
    width: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big .type2-big-content {
    width: 100%;
    margin-left: 0px;
  }
}

.Chapter-container .type2 .type2-big .type2-big-content .type2-title {
  margin-bottom: 10px;
  font-size: 2.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big .type2-big-content .type2-title {
    font-size: 2.46rem;
  }
}

.Chapter-container .type2 .type2-big .type2-big-content .para {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
}

.Chapter-container .type2 .type2-big .type2-left-content {
  margin-left: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-big .type2-left-content {
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big .type2-left-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2 .type2-big .type2-right-content {
  margin-right: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-big .type2-right-content {
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-big .type2-right-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2 .type2-small {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-small {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small {
    flex-direction: column;
  }
}

.Chapter-container .type2 .type2-small .type2-small-image {
  width: 30%;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-small .type2-small-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small .type2-small-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

.Chapter-container .type2 .type2-small .type2-small-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-small .type2-small-content {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small .type2-small-content {
    width: 100%;
    margin-left: 0px;
  }
}

.Chapter-container .type2 .type2-small .type2-small-content .type2-title {
  margin-bottom: 10px;
  font-size: 2.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small .type2-small-content .type2-title {
    font-size: 2.46rem;
  }
}

.Chapter-container .type2 .type2-small .type2-small-content .para {
  margin: 0;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  text-align: justify;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
}

.Chapter-container .type2 .type2-small .type2-left-content {
  margin-left: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-small .type2-left-content {
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small .type2-left-content {
    margin-left: 0px;
  }
}

.Chapter-container .type2 .type2-small .type2-right-content {
  margin-right: 30px;
}

@media (max-width: 1200px) {
  .Chapter-container .type2 .type2-small .type2-right-content {
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type2 .type2-small .type2-right-content {
    margin-right: 0px;
  }
}

.Chapter-container .type3 {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 640px) {
  .Chapter-container .type3 {
    margin-top: 20px;
  }
}

.Chapter-container .type3 .type3-image-container {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Chapter-container .type3 .type3-image-container .type3-image {
  width: 100%;
  object-fit: contain;
}

.Chapter-container .type3 .type3-image-container .type3-title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1.86rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .Chapter-container .type3 .type3-image-container .type3-title {
    font-size: 0.86rem;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type3 .type3-image-container .type3-title {
    font-size: 0.76rem;
    text-align: left;
    font-weight: 800;
    margin-left: 5px;
  }
}

.Chapter-container .type3-highlighted {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .Chapter-container .type3-highlighted {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 640px) {
  .Chapter-container .type3-highlighted {
    margin-top: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.Chapter-container .type3-highlighted .type3-image-container {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 640px) {
  .Chapter-container .type3-highlighted .type3-image-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Chapter-container .type3-highlighted .type3-image-container .type3-image {
  width: 100%;
  object-fit: contain;
}

.Chapter-container .type3-highlighted .type3-image-container .type3-title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1.86rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .Chapter-container .type3-highlighted .type3-image-container .type3-title {
    font-size: 0.86rem;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type3-highlighted .type3-image-container .type3-title {
    font-size: 0.76rem;
    text-align: left;
    font-weight: 800;
    margin-left: 5px;
  }
}

.Chapter-container .type4-highlighted {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1200px) {
  .Chapter-container .type4-highlighted {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 640px) {
  .Chapter-container .type4-highlighted {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Chapter-container .type4-highlighted .type4-image {
  width: 100%;
  object-fit: contain;
}

.Chapter-container .type4-highlighted .type4-title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1.86rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type4-highlighted .type4-title {
    font-size: 1.46rem;
    width: 100%;
    word-break: break-all;
  }
}

.Chapter-container .type4 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .Chapter-container .type4 {
    margin-top: 20px;
  }
}

.Chapter-container .type4 .type4-image {
  width: 100%;
  object-fit: contain;
}

.Chapter-container .type4 .type4-title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1.86rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  word-break: break-all;
}

@media (max-width: 640px) {
  .Chapter-container .type4 .type4-title {
    font-size: 1.46rem;
    width: 80%;
  }
}

.Chapter-container .type5-highlighted {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1200px) {
  .Chapter-container .type5-highlighted {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Chapter-container .type5-highlighted .type5-images {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
}

@media (max-width: 1200px) {
  .Chapter-container .type5-highlighted .type5-images {
    height: 40vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted .type5-images {
    height: 40vh;
  }
}

.Chapter-container .type5-highlighted .type5-images .type5-image-5 {
  width: 30%;
  height: 80vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5-highlighted .type5-images .type5-image-5 {
    height: 40vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted .type5-images .type5-image-5 {
    height: 40vh;
  }
}

.Chapter-container .type5-highlighted .type5-images .type5-group-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-l {
  width: 69%;
  height: 40vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-l {
    height: 20vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-l {
    height: 20vh;
  }
}

.Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-p {
  width: 30%;
  height: 40vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-p {
    height: 20vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted .type5-images .type5-group-container .type5-image-p {
    height: 20vh;
  }
}

.Chapter-container .type5-highlighted .type5-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type5-highlighted .type5-title {
    font-size: 2.46rem;
    margin-top: 20px;
    word-break: break-all;
  }
}

.Chapter-container .type5 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 640px) {
  .Chapter-container .type5 {
    margin-top: 20px;
  }
}

.Chapter-container .type5 .type5-images {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
}

@media (max-width: 1200px) {
  .Chapter-container .type5 .type5-images {
    height: 40vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5 .type5-images {
    height: 40vh;
  }
}

.Chapter-container .type5 .type5-images .type5-image-5 {
  width: 30%;
  height: 80vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5 .type5-images .type5-image-5 {
    height: 40vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5 .type5-images .type5-image-5 {
    height: 40vh;
  }
}

.Chapter-container .type5 .type5-images .type5-group-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Chapter-container .type5 .type5-images .type5-group-container .type5-image-l {
  width: 69%;
  height: 40vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5 .type5-images .type5-group-container .type5-image-l {
    height: 20vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5 .type5-images .type5-group-container .type5-image-l {
    height: 20vh;
  }
}

.Chapter-container .type5 .type5-images .type5-group-container .type5-image-p {
  width: 30%;
  height: 40vh;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Chapter-container .type5 .type5-images .type5-group-container .type5-image-p {
    height: 20vh;
  }
}

@media (max-width: 640px) {
  .Chapter-container .type5 .type5-images .type5-group-container .type5-image-p {
    height: 20vh;
  }
}

.Chapter-container .type5 .type5-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 640px) {
  .Chapter-container .type5 .type5-title {
    font-size: 2.46rem;
    margin-top: 20px;
  }
}

.Chapter-container .Chapter-spotify-container {
  height: 110px;
  width: 320px;
  background-color: #3c3c3c;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: -webkit-sticky;
  /* Safari */
  position: fixed;
  bottom: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px 0 0 14px;
  border-width: 1px;
  border-color: #ffb71b;
  border-right: none;
  box-shadow: -1px 0 6px rgba(0, 0, 0, 0.26);
  z-index: 9;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-spotify-container {
    height: 110px;
    width: 320px;
    border-radius: 5px 0 0 5px;
    background-color: #3c3c3c;
  }
}

.Chapter-container .Chapter-spotify-container .Chapter-spotify-theme-song {
  color: #ffb71b;
  font-size: 0.9rem;
  width: 300px;
  text-align: left;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 2px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-spotify-container .Chapter-spotify-theme-song {
    width: 300px;
  }
}

.Chapter-container .Chapter-spotify-container .Chapter-spotify {
  border-radius: 10px;
}

.Chapter-container .Chapter-spotify-container-hidden {
  height: 110px;
  width: 100px;
  background-color: #3c3c3c;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: -webkit-sticky;
  /* Safari */
  position: fixed;
  bottom: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px 0 0 14px;
  border-width: 1px;
  border-color: #ffb71b;
  border-right: none;
  box-shadow: -1px 0 6px rgba(0, 0, 0, 0.26);
  z-index: 9;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-spotify-container-hidden {
    height: 110px;
    width: 50px;
    border-radius: 5px 0 0 5px;
    background-color: #3c3c3c;
  }
}

.Chapter-container .Chapter-spotify-container-hidden .Chapter-spotify-theme-song {
  color: #ffb71b;
  font-size: 0.9rem;
  width: 50px;
  text-align: left;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 2px;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-spotify-container-hidden .Chapter-spotify-theme-song {
    width: 50px;
    font-weight: 800;
    margin-left: 20px;
  }
}

.Chapter-container .Chapter-spotify-container-hidden .Chapter-spotify {
  border-radius: 10px;
}

.Chapter-container .Chapter-switch-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 1.86rem;
}

@media (max-width: 1200px) {
  .Chapter-container .Chapter-switch-container {
    justify-content: flex-start;
  }
}

.Chapter-container .Chapter-switch-container .Chapter-switch-back {
  background-color: #ffb71b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 40px;
  border-radius: 5px;
  box-shadow: -1px 0 6px rgba(0, 0, 0, 0.35);
  opacity: 0.6;
  transition: 0.3s;
}

.Chapter-container .Chapter-switch-container .Chapter-switch-back:hover {
  opacity: 1;
}

@media (max-width: 1200px) {
  .Chapter-container .Chapter-switch-container .Chapter-switch-back {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-switch-container .Chapter-switch-back {
    margin-right: 20px;
  }
}

.Chapter-container .Chapter-switch-container .Chapter-switch-back .Chapter-switch-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.Chapter-container .Chapter-switch-container .Chapter-switch-back .Chapter-switch-link .Chapter-switch-text {
  text-decoration: none;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-left: 5px;
  padding-right: 5px;
}

.Chapter-container .Chapter-switch-container .Chapter-chapterCounter {
  font-size: 0.86rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  background-color: #333330;
  height: 75%;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: white;
}

@media (max-width: 640px) {
  .Chapter-container .Chapter-switch-container .Chapter-chapterCounter {
    margin-right: 20px;
  }
}

.Chapter-container .Chapter-switch-container .Chapter-switch-next {
  color: #164734;
  background-color: #ffb71b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0 6px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  opacity: 0.6;
  transition: 0.3s;
}

.Chapter-container .Chapter-switch-container .Chapter-switch-next:hover {
  opacity: 1;
}

@media (max-width: 1200px) {
  .Chapter-container .Chapter-switch-container .Chapter-switch-next {
    opacity: 1;
  }
}

.Chapter-container .Chapter-switch-container .Chapter-switch-next .Chapter-switch-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.Chapter-container .Chapter-switch-container .Chapter-switch-next .Chapter-switch-link .Chapter-switch-text {
  text-decoration: none;
  font-family: 'IBM Plex Serif', serif, Georgia, Times, serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-right: 5px;
  padding-left: 5px;
}
/*# sourceMappingURL=Blog.css.map */
.Home-theme-design-container {
  width: 100%;
  height: 10vh;
  background-color: #473030;
}

.Home-theme-design-container .Home-theme-design-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Home-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Home-main .Home-sidebar-container {
  padding-top: 10vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Home-main .Home-sidebar-container .Home-sidebar-feature {
  height: 300px;
  width: 200px;
  background-color: #164734;
  margin-left: 20px;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.Home-main .Home-sidebar-container .Home-sidebar-feature .Home-sidebar-feature-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.Home-main .Home-sidebar-container .Home-sidebar-title {
  margin-left: 20px;
  width: 62%;
  margin-top: 10px;
  font-size: 1.36rem;
  word-wrap: break-word;
  font-weight: 800;
  text-align: left;
  color: #333330;
  line-height: 130%;
}

.Home-main .Home-sidebar-container .Home-sidebar-link {
  width: 45%;
  height: 40px;
  border-radius: 7px;
  margin-left: 20px;
  text-decoration: none;
  cursor: pointer;
}

.Home-main .Home-sidebar-container .Home-sidebar-link .Home-sidebar-button {
  width: 100%;
  height: 100%;
  background-color: #164734;
  color: #ffb71b;
  font-size: 0.7rem;
  font-weight: 800;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-style: none;
  border-radius: 7px;
  padding: 15px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .Home-main .Home-sidebar-container {
    display: none;
  }
}

.Home-main .Home-content-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2vh;
}

.Home-main .Home-content-container .Home-trips-label {
  margin: 0;
  margin-bottom: 2vh;
  font-size: 1.6em;
  word-wrap: break-word;
  width: 100%;
  text-align: left;
  font-weight: 700;
  margin-right: auto;
  margin-top: 20px;
  text-transform: uppercase;
}

.Home-main .Home-content-container .Home-trips-label-1 {
  margin-top: 0px;
}

.Home-main .Home-content-container .Home-covers {
  margin: 0;
  margin-bottom: 2vh;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-decoration: none;
}

.Home-main .Home-content-container .Home-covers .Home-cover-container {
  margin-top: 0px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 142px;
  cursor: pointer;
  text-decoration: none;
}

.Home-main .Home-content-container .Home-covers .Home-cover-container .Home-cover {
  height: 200px;
  width: 142px;
  aspect-ratio: 1/1.414;
  border-radius: 5px;
  background-color: #000000;
  text-decoration: none;
}

.Home-main .Home-content-container .Home-covers .Home-cover-container .Home-cover .Home-cover-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.Home-main .Home-content-container .Home-covers .Home-cover-container .Home-cover-label {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  word-wrap: break-word;
  font-weight: 800;
  margin-top: 5px;
  text-decoration: none;
  color: #333330;
}

.Home-main .Home-content-container .Home-content-container-trips {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .Home-main .Home-content-container .Home-content-container-trips {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .Home-main .Home-content-container {
    width: 100vw;
    margin-left: 5vw;
  }
}

.Home-BlogAsThumbnail-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}

.Home-BlogAsThumbnail-container .BlogAsThumbnail {
  height: 100%;
  margin-right: 20px;
}

.Home-BlogAsThumbnail-container .BlogAsThumbnail .BlogAsThumbnail-container {
  margin: 0;
  width: 250px;
  height: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  position: relative;
}

.Home-BlogAsThumbnail-container .BlogAsThumbnail .BlogAsThumbnail-container .BlogAsThumbnail-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.Home-BlogAsThumbnail-container .BlogAsThumbnail .BlogAsThumbnail-container .BlogAsThumbnail-text-container {
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 45%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
}

.Home-BlogAsThumbnail-container .BlogAsThumbnail .BlogAsThumbnail-container .BlogAsThumbnail-text-container .BlogAsThumbnail-text {
  color: white;
  text-align: left;
  text-transform: uppercase;
  margin-left: 20px;
  text-shadow: 0 0 5px black;
  font-size: 1.26rem;
  word-wrap: break-word;
  font-weight: 700;
}

.BlogShowCase-container {
  width: 80%;
  max-width: 1706px;
  height: 765px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.BlogShowCase-container .BlogShowCase-profile-container {
  width: 100%;
  height: 120px;
  margin-bottom: 50px;
}

.BlogShowCase-container .BlogShowCase-blog-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 550px;
  width: 100%;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 7px 30px rgba(0, 0, 0, 0.07);
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-image {
  width: 100%;
  aspect-ratio: 3/2;
  max-height: 77%;
  object-fit: cover;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container {
  width: 100%;
  height: 23%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-left-container {
  height: 100%;
  aspect-ratio: 1/1;
  max-width: 20%;
  border-style: solid;
  border-width: 2px;
  border-color: #333330;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.26);
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-center-container {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #333330;
  padding-left: 20px;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-center-container .BlogShowCase-trip-title {
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 800;
  margin: 0;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-center-container .BlogShowCase-trip-cities {
  margin: 0;
  font-size: 0.86rem;
  word-wrap: break-word;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-right-container {
  height: 100%;
  min-width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #333330;
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 800;
  margin: 0;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-right-container .BlogShowCase-budget-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: #333330;
  margin-right: 20px;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-right-container .BlogShowCase-budget-container .BlogShowCase-trip-budget {
  font-size: 1.2rem;
  word-wrap: break-word;
  font-weight: 800;
  margin: 0;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-left-container .BlogShowCase-info-container .BlogShowCase-info-right-container .BlogShowCase-budget-container .BlogShowCase-trip-budget-label {
  font-size: 0.6rem;
  word-wrap: break-word;
  font-weight: 500;
  margin: 0;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-right-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #333330;
  box-shadow: 0 7px 30px rgba(0, 0, 0, 0.07);
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-right-container .BlogShowCase-summary-label {
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0;
  margin-top: 50px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
}

.BlogShowCase-container .BlogShowCase-blog-container .BlogShowCase-right-container .BlogShowCase-summary {
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 500;
  margin: 0;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  text-align: justify;
  letter-spacing: 1px;
}
/*# sourceMappingURL=Home.css.map */
.BlogAsSmall-container {
  width: 400px;
  margin: 0px;
  margin-bottom: 20px;
  margin-right: 30px;
  text-decoration: none;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container {
    width: 300px;
    height: 240px;
    box-shadow: none;
  }
}

@media (max-width: 640px) {
  .BlogAsSmall-container {
    width: 300px;
    height: 240px;
    box-shadow: none;
  }
}

@media (max-width: 325px) {
  .BlogAsSmall-container {
    width: 260px;
    height: 230px;
    margin-right: 0px;
    box-shadow: none;
  }
}

@media (max-width: 290px) {
  .BlogAsSmall-container {
    width: 260px;
    height: 230px;
    margin-right: 0px;
    box-shadow: none;
  }
}

.BlogAsSmall-container .BlogAsSmall-image-box {
  height: 224px;
  width: 100%;
  border-radius: 10px;
  background-color: #212530;
  position: relative;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-image-box {
    height: 70%;
  }
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogShowCase-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container {
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-top-div {
  margin-bottom: auto;
  margin-top: 20px;
  top: 0px;
  right: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-top-div .BlogAsSmall-image-hover-label {
  color: white;
  font-size: 0.80rem;
  margin: 0px;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-top-div .BlogAsSmall-image-hover-text {
  color: #ffb71b;
  font-size: 1rem;
  margin: 0px;
  font-weight: 800;
  text-align: left;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-button {
  border: solid 1px white;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  text-transform: uppercase;
  width: 60px;
  height: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  cursor: pointer;
  transition: 0.3s;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-bottom-div {
  margin-top: auto;
  margin-bottom: 20px;
  bottom: 0px;
  left: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-bottom-div .BlogAsSmall-image-hover-label {
  color: white;
  font-size: 0.80rem;
  margin: 0px;
}

.BlogAsSmall-container .BlogAsSmall-image-box .BlogAsSmall-image-hover-container .BlogAsSmall-image-hover-bottom-div .BlogAsSmall-image-hover-text {
  color: #ffb71b;
  font-size: 0.86rem;
  margin: 0px;
  text-align: left;
}

.BlogAsSmall-container .BlogAsSmall-image-box .hoverOn {
  display: block;
}

.BlogAsSmall-container .BlogAsSmall-image-box .hoverOff {
  display: none;
}

.BlogAsSmall-container .BlogAsSmall-info-box {
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-profile-image-container {
  width: 15%;
  height: 100%;
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-profile-image-container .BlogAsSmall-profile-image {
  margin-top: 10px;
  width: 80%;
  aspect-ratio: 1 / 1;
  border-radius: 10%;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-profile-image-container .BlogAsSmall-profile-image {
    margin-top: 3px;
  }
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-trip-title {
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 700;
  padding: 0px;
  margin: 0;
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #333330;
  max-height: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-trip-title {
    font-size: 0.70rem;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-channel-name {
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 500;
  padding: 0px;
  margin: 0;
  text-align: left;
  text-transform: capitalize;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  color: #333330;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-channel-name {
    font-size: 0.60rem;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-flag-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 5%;
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-flag-container .BlogAsSmall-flag-each-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-flag-container .BlogAsSmall-flag-each-container .BlogProfile-flag {
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  height: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border: solid 1px black;
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-flag-container .BlogAsSmall-flag-each-container .BlogProfile-flag {
    height: 8px;
  }
}

@media (max-width: 1200px) {
  .BlogAsSmall-container .BlogAsSmall-info-box .BlogAsSmall-info-container .BlogAsSmall-flag-container {
    height: 0%;
    margin-left: 5px;
    margin-right: 5px;
  }
}
/*# sourceMappingURL=BlogAsSmall.css.map */
.dateButton {
  height: 40px;
  width: 150px;
  background-color: #333330;
  color: #ffb71b;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  border-style: none;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=CustomDatePicker.css.map */
.flagContainer {
  background-color: #333330;
  width: 125px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin-left: 10px;
  margin-bottom: 5px;
  padding: 5px;
}

@media (max-width: 640px) {
  .flagContainer {
    width: 70px;
    height: 30px;
    margin-left: 0px;
    margin-right: 10px;
  }
}

.flagContainer .xContainer {
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

@media (max-width: 640px) {
  .flagContainer .xContainer {
    margin-left: 0px;
  }
}

.flagContainer .xContainer .xButton {
  background-color: transparent;
  color: #ffb71b;
  border-style: none;
  font-size: 1.8rem;
  font-weight: 800;
  height: 20px;
  margin-top: 0;
  margin-bottom: 7px;
  padding: 0;
  cursor: pointer;
}

@media (max-width: 640px) {
  .flagContainer .xContainer .xButton {
    font-size: 0.86rem;
  }
}

.flagContainer .flagBox {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flagContainer .flagBox .flag {
  height: 35px;
  padding: 0;
  border-radius: 2px;
}

@media (max-width: 640px) {
  .flagContainer .flagBox .flag {
    height: 20px;
  }
}
/*# sourceMappingURL=CountryTag.css.map */
.UploadBlog-uploadContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.UploadBlog-uploadContainer .uploadContainer {
  /*
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  */
  width: 90%;
  max-width: 1200px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container {
    flex-direction: column;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons {
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-publish-button {
  width: 150px;
  height: 40px;
  background-color: transparent;
  color: #164734;
  font-weight: 800;
  border: solid 1px #164734;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-publish-button:hover {
  background-color: #164734;
  color: #ffb71b;
  border-style: none;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-publish-button {
    width: 100px;
    height: 40px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-unpublish-button {
  width: 150px;
  height: 40px;
  background-color: #164734;
  color: #ffb71b;
  font-weight: 800;
  border-style: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-unpublish-button:hover {
  background-color: transparent;
  color: #164734;
  border: solid 1px #164734;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-unpublish-button {
    width: 100px;
    height: 40px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-delete-button {
  width: 150px;
  height: 40px;
  background-color: #E04E4E;
  color: #ffb71b;
  font-weight: 800;
  border-style: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-delete-button:hover {
  background-color: #da6b6b;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-delete-button {
    width: 100px;
    height: 40px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-addBudget-button {
  width: 150px;
  height: 40px;
  background-color: #164734;
  color: #ffb71b;
  font-weight: 800;
  border-style: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-addBudget-button:hover {
  background-color: #ffb71b;
  color: #164734;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .UploadBlog-title-container .UploadBlog-title-buttons .UploadBlog-addBudget-button {
    width: 100px;
    height: 40px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .upload-blog-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-row2 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .upload-blog-row2 {
    flex-direction: column;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-title {
  font-size: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  min-width: 300px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-title {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-date {
  min-width: 300px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-date {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-date-datecontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
}

.UploadBlog-uploadContainer .uploadContainer .trip-date-datecontainer .trip-date-label {
  font-size: 0.76rem;
  font-weight: 800;
  margin-top: 10px;
  color: #808080;
}

.UploadBlog-uploadContainer .uploadContainer .trip-countries {
  min-width: 300px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-countries {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-cities {
  min-width: 300px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-cities {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-summary {
  min-width: 300px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-summary {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-feature-image {
  min-width: 300px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-feature-image {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .trip-add-chapters {
  margin-right: 30px;
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .trip-add-chapters {
    min-width: 100px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .trip-save-chapter-order-button {
  background-color: #333330;
  width: 100px;
  height: 40px;
  border-style: none;
  color: #ffb71b;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s;
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .trip-save-chapter-order-button:hover {
  background-color: #164734;
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .displayOff {
  display: none;
}

.UploadBlog-uploadContainer .uploadContainer .upload-blog-chapters-title-container .displayOn {
  display: block;
}

.UploadBlog-uploadContainer .uploadContainer .trip-summary-container {
  max-height: 100%;
  width: 60%;
  display: flex;
  align-items: left;
  justify-content: start;
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-summary-container {
    width: 100%;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-countries-values {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-countries-values {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-countries-values {
    flex-direction: column;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-countries-values .trip-countries-chips {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-countries-values .trip-countries-chips {
    margin-top: 10px;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-countries-values .trip-countries-chips {
    margin-top: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-notsavedblog-placeholder {
  text-align: left;
  font-weight: 800;
  color: #9c9c9c;
}

.UploadBlog-uploadContainer .uploadContainer .rContainer {
  width: 768px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .rContainer {
    width: 100%;
  }
}

.UploadBlog-uploadContainer .uploadContainer .r2Container {
  width: 740px;
  margin-left: 40px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .r2Container {
    width: 100%;
    margin-left: 0px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  margin-left: 5px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer .imagePreview {
    border-radius: 5px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer .imagePreview .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer .imagePreview .img {
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
}

.UploadBlog-uploadContainer .uploadContainer .r2Container .featureImageContainer .imageTools {
  display: flex;
  justify-content: center;
  background-color: red;
  width: 100%;
}

.UploadBlog-uploadContainer .uploadContainer .lContainer {
  width: 256px;
  text-align: left;
}

.UploadBlog-uploadContainer .uploadContainer .titleInput {
  background-color: #f8f8f8;
  border: solid 1px #f1f1f1;
  border-radius: 10px;
  width: 100%;
}

.UploadBlog-uploadContainer .uploadContainer .citiesInput {
  background-color: #f8f8f8;
  border: solid 1px #f1f1f1;
  border-radius: 5px;
  padding: 12px 10px;
  font-size: 1.4rem;
  font-family: "Alegreya Sans", sans-serif;
  width: 100%;
}

.UploadBlog-uploadContainer .uploadContainer .inputTitle {
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .inputTitle {
    padding: 12px 0px;
    border-radius: 5px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .summary {
  height: 200px;
  padding: 20px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 10px;
}

.UploadBlog-uploadContainer .uploadContainer .dateContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.UploadBlog-uploadContainer .uploadContainer .dateContainer .datePicker {
  margin-left: 0px;
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container {
  width: 96%;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container {
    width: 100%;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container {
    flex-direction: column;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar {
  width: 90%;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar {
    width: 100%;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-bar-dragButton {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-bar-dragButton {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-bar-dragButton {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-bar-index {
  margin-right: 20px;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-bar-index {
    margin-right: 10px;
  }
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner {
    width: 100%;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container {
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container {
    margin-right: 20px;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container {
    margin-right: 5px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container .chapterInput {
  background-color: #f8f8f8;
  border-style: none;
  border-radius: 10px;
  width: 100%;
  min-width: 650px;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container .chapterInput {
    min-width: 260px;
  }
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .trip-chapter-chapterInput-container .chapterInput {
    min-width: 0px;
    border-radius: 5px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .inputChapter {
  padding: 12px 20px;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
}

@media (max-width: 640px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-bar-inner .inputChapter {
    padding: 12px 10px;
    font-size: 0.86rem;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-save-button {
  background-color: transparent;
  color: #17aa4a;
  border-style: none;
  font-weight: 800;
  cursor: pointer;
  transition: 0.4s;
  height: 44px;
  width: 60px;
  border-radius: 5px;
  font-size: 1rem;
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-save-button:hover {
  color: white;
  background-color: #17aa4a;
}

@media (max-width: 1200px) {
  .UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-save-button {
    margin-right: 10px;
  }
}

.UploadBlog-uploadContainer .uploadContainer .trip-chapters-container .trip-chapter-container .trip-chapter-bar .trip-chapter-view-button {
  background-color: transparent;
  color: #333330;
  border-style: none;
  font-weight: 800;
  cursor: pointer;
  transition: 0.4s;
  height: 44px;
  width: 60px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0;
  padding-top: 2px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-add-chapter-button {
  border: none;
  background-color: transparent;
  font-weight: 800;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 4px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-floating-container {
  background-color: #333330;
  color: #ffb71b;
  border-radius: 16px;
  width: 70%;
  height: 65px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  /*
    .UploadBlog-floating-progressbar{
      width:85%;

      progress::-webkit-progress-bar {
        background-color: yellow;
        border-radius: 7px;
      }
      progress::-webkit-progress-value {
        background-color: blue;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba( 255, 0, 0, 0.8 );
      }
    }*/
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-floating-container .UploadBlog-floating-progressbar-container {
  width: 85%;
  height: 10px;
  background-color: black;
  border-radius: 10px;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-floating-container .UploadBlog-floating-progressbar-container .UploadBlog-floating-progressbar {
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ffb71b;
}

.UploadBlog-uploadContainer .uploadContainer .UploadBlog-floating-container .UploadBlog-floating-button {
  background-color: transparent;
  border-style: none;
  color: #ffb71b;
  font-weight: 800;
  font-size: 1.2rem;
  cursor: pointer;
}

.blocksContainer {
  width: 80vw;
  margin: auto;
  align-items: center;
  max-height: 100%;
}

@media (max-width: 640px) {
  .blocksContainer {
    width: 90%;
  }
}

.blocksContainer .UploadChapter-title-container {
  /* margin-top: 10vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
*/
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-title-container {
    width: 100%;
    flex-direction: column;
  }
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.86rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

@media (max-width: 1200px) {
  .blocksContainer .UploadChapter-title-container .UploadChapter-title {
    font-size: 1.0rem;
  }
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-title-container .UploadChapter-title {
    font-size: 1.0rem;
    margin-bottom: 10px;
    width: 100%;
  }
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title p {
  padding: 0;
  margin: 0;
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-back {
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #164734;
  width: 80px;
  height: 35px;
  transition: 0.3s;
  border: solid 2px #164734;
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-back:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-back {
    width: 47%;
  }
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-save {
  margin-left: 20px;
  background-color: #164734;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #ffb71b;
  width: 80px;
  height: 35px;
}

.blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-save:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-title-container .UploadChapter-title-buttons .UploadChapter-title-save {
    width: 47%;
  }
}

.blocksContainer .UploadChapter-feature-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-feature-image-container {
    flex-direction: column;
  }
}

.blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-label {
  font-size: 1.26rem;
  width: 20%;
  text-align: left;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-label {
    width: 30%;
  }
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-label {
    width: 100%;
    margin-left: 0px;
  }
}

.blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-preview {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-preview {
    margin-top: 5px;
    width: 100%;
  }
}

.blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-preview .uploadImageLabel {
  width: 100%;
}

.blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-preview .uploadImageLabel .img {
  width: 100%;
  border-radius: 6px;
  border-style: solid;
  border-width: 3px;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-feature-image-container .UploadChapter-feature-image-preview .uploadImageLabel .img {
    border-width: 1px;
  }
}

.blocksContainer .UploadChapter-spotify-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-spotify-container {
    flex-direction: column;
  }
}

.blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-label {
  font-size: 1.26rem;
  width: 20%;
  text-align: left;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-label {
    width: 30%;
  }
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-label {
    width: 100%;
    margin-left: 0px;
  }
}

.blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container {
    width: 30%;
  }
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container {
    margin-top: 5px;
    width: 100%;
  }
}

.blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container .UploadChapter-spotify-textfield {
  width: 100%;
  background-color: #f8f8f8;
  border-style: solid;
  border-color: #f2f2f2;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.86rem;
  color: #333330;
  font-weight: 800;
  height: 30px;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container .UploadChapter-spotify-textfield {
    padding-left: 5px;
    padding-right: 5px;
    width: 95%;
    border-radius: 2px;
  }
}

.blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container .UploadChapter-spotify-iframe {
  margin-top: 20px;
  border-radius: 14px;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-spotify-container .UploadChapter-spotify-textfield-container .UploadChapter-spotify-iframe {
    margin-top: 5px;
    border-radius: 7px;
  }
}

.blocksContainer .UploadChapter-blocks-label-container {
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .blocksContainer .UploadChapter-blocks-label-container {
    margin-top: 10px;
  }
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-blocks-label-container {
    margin-top: 5px;
  }
}

.blocksContainer .UploadChapter-blocks-label-container .UploadChapter-blocks-label-p {
  text-align: left;
  font-size: 1.2rem;
  padding: 20px;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .blocksContainer .UploadChapter-blocks-label-container .UploadChapter-blocks-label-p {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
  }
}

.blocksContainer .UploadChapter-individual-block-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: none;
  width: 100%;
}

.blocksContainer .blockCard {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .blocksContainer .blockCard {
    padding: 10px;
    border-radius: 7px;
  }
}

.blocksContainer .blockCard .top {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.blocksContainer .blockCard .top .titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
}

@media (max-width: 640px) {
  .blocksContainer .blockCard .top .titleInput {
    padding: 5px 10px;
    font-size: 1.0rem;
    border-radius: 5px;
  }
}

.blocksContainer .blockCard .bottom {
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.blocksContainer .blockCard .bottom .type1Text {
  height: 200px;
  width: 80%;
  padding: 20px 20px 20px 20px;
  border-radius: 14px;
  border-style: solid;
  background-color: #d7d7d8;
  border-color: #fcf9f9;
}

.blocksContainer .blockCard .bottom .textContainer1 {
  max-height: 100%;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: start;
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .blocksContainer .blockCard .bottom .textContainer1 {
    margin-bottom: 10px;
  }
}

.blocksContainer .blockCard .bottom .textContainer {
  max-height: 100%;
  width: 47%;
  display: flex;
  align-items: left;
  justify-content: start;
}

.blocksContainer .blockCard .bottom .textContainer .type2Text {
  height: 200px;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  resize: vertical;
  padding: 20px 20px 20px 20px;
  border-radius: 14px;
  border-style: solid;
  border-color: white;
  display: block;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.blocksContainer .blockCard .bottom .fullImageContainer {
  max-height: 100%;
  width: 100%;
}

.blocksContainer .blockCard .bottom .fullImageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
}

.blocksContainer .blockCard .bottom .fullImageContainer .imagePreview .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.blocksContainer .blockCard .bottom .fullImageContainer .imageTools {
  display: flex;
  justify-content: center;
  background-color: red;
  width: 100%;
  padding: 10px;
}

.blocksContainer .blockCard .bottom .fullImageContainer .imageTools .uploadImageLabel {
  font-family: "Alegreya Sans", sans-serif;
  text-transform: lowercase;
  color: #ffb71b;
  background-color: #010000;
  padding: 5px 20px 5px 20px;
  border-radius: 4px;
}

.blocksContainer .blockCard .bottom .fullImageContainer .imageTools .deleteImage {
  margin-left: 20px;
  padding: 0 10px;
  background-color: #a00000;
  color: white;
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  font-family: "Alegreya Sans", sans-serif;
}

.blocksContainer .blockCard .bottom .imageContainer {
  max-height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blocksContainer .blockCard .bottom .imageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blocksContainer .blockCard .bottom .imageContainer .imagePreview .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.blocksContainer .blockCard .bottom .imageContainer .imageTools {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.blocksContainer .blockCard .bottom .imageContainer .imageTools .uploadImageLabel {
  font-family: "Alegreya Sans", sans-serif;
  text-transform: lowercase;
  color: #ffb71b;
  background-color: #010000;
  padding: 5px 20px 5px 20px;
  border-radius: 4px;
}

.blocksContainer .blockCard .bottom .imageContainer .imageTools .deleteImage {
  margin-left: 0px;
  padding: 0 0px;
  background-color: #333330;
  color: #ffb71b;
  border: none;
  border-width: 1px;
  border-radius: 4px;
  font-family: "Alegreya Sans", sans-serif;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.blocksContainer .blockCard .gallery5 {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.blocksContainer .blockCard .gallery5 > * {
  background-color: #f8f8f8;
}

.blocksContainer .blockCard .gallery5 .group {
  width: 100%;
  height: 624px;
}

.blocksContainer .blockCard .gallery5 .group .imageContainer {
  height: 312px;
  border-style: dashed;
  border-width: 1px;
  border-color: lightblue;
}

.blocksContainer .blockCard .gallery5 .group .imageContainer .img {
  width: 100%;
  height: 312px;
  object-fit: cover;
  cursor: pointer;
}

.blocksContainer .blockCard .gallery5 .group .colo {
  display: grid;
  grid-template-columns: 70% 30%;
  height: 312px;
}

.blocksContainer .blockCard .gallery5 .group .colo2 {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 312px;
}

.blocksContainer .blockCard .gallery5 .side {
  width: 100%;
  height: 624px;
  border-style: dashed;
  border-width: 1px;
  border-color: lightblue;
}

.blocksContainer .blockCard .gallery5 .side .colo {
  height: 100%;
}

.blocksContainer .blockCard .gallery5 .side .colo .imageContainer {
  height: 624px;
}

.blocksContainer .blockCard .gallery5 .side .colo .imageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blocksContainer .blockCard .gallery5 .side .colo .imageContainer .imagePreview .img {
  width: 100%;
  height: 624px;
  object-fit: cover;
  cursor: pointer;
}

.blocksContainer .blockCard .gallery5 .side .colo .imageContainer .uploadImageLabel {
  height: 624px;
}

.blocksContainer .blockCard .gallery5 .imageContainer {
  width: 100%;
}

.blocksContainer .blockCard .gallery5 .imageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blocksContainer .blockCard .gallery5 .imageContainer .imagePreview .img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.blocksContainer .blockCard .gallery5 .imageContainer .imageTools {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blocksContainer .blockCard .gallery5 .imageContainer .imageTools .uploadImageLabel {
  height: 312px;
  font-family: "Alegreya Sans", sans-serif;
  text-transform: lowercase;
  color: #ffb71b;
  padding: 5px 20px 5px 20px;
}

.blocksContainer .blockCard .tools {
  border-style: solid;
  width: 95%;
}

.blocksContainer .blockCard .UploadBlock-title {
  width: 100%;
  font-weight: 800;
}

.blocksContainer .blockCard .types {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}

@media (max-width: 640px) {
  .blocksContainer .blockCard .types {
    flex-direction: column;
  }
}

.blocksContainer .blockCard .types .UploadBlocks-defaultImage-button {
  width: 81px;
  height: 55px;
  cursor: pointer;
  border: solid 1px #e2e2e2;
  border-radius: 2px;
}

@media (max-width: 640px) {
  .blocksContainer .blockCard .types .UploadBlocks-defaultImage-button {
    width: 100px;
    height: 68px;
  }
}

.blocksContainer .UploadChapter-add-button {
  margin: 0px;
  padding: 0px;
  font-family: "Alegreya Sans", sans-serif;
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  font-size: 2.86rem;
  font-weight: 800;
}

.blocksContainer .UploadChapter-floating-container {
  min-height: 40px;
  min-width: 120px;
  background-color: rgba(255, 255, 255, 0.26);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: -webkit-sticky;
  /* Safari */
  position: fixed;
  bottom: 20px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 14px 0 0 14px;
  border-width: 1px;
  border-color: #ffb71b;
  border-right: none;
  box-shadow: -1px 0 6px rgba(0, 0, 0, 0.26);
  padding: 40px;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-save-block-order-button {
  background-color: #333330;
  width: 100px;
  height: 40px;
  border: solid 2px #333330;
  color: #ffb71b;
  font-weight: 800;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-save-block-order-button:hover {
  background-color: transparent;
  color: #333330;
  border: solid 2px #333330;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-floating-save {
  background-color: #164734;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 800;
  color: #ffb71b;
  width: 120px;
  height: 40px;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-floating-save:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-floating-preview {
  background-color: #333330;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 800;
  color: #ffb71b;
  width: 120px;
  height: 40px;
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.86rem;
}

.blocksContainer .UploadChapter-floating-container .UploadChapter-floating-preview:hover {
  background-color: #ffb71b;
  color: #333330;
}

.blocksContainer .UploadChapter-floating-container .displayOff {
  display: none;
}

.blocksContainer .UploadChapter-floating-container .displayOn {
  display: block;
}

.blocksContainer .UploadChapter-floating-container .displayFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #17aa4a;
}

.blocksContainer .UploadChapter-floating-container .Chapter-spotify {
  border-radius: 10px;
}

.Type1-upload-container {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .Type1-upload-container {
    padding: 10px;
    border-radius: 7px;
  }
}

.Type1-upload-container .Type1-upload-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .Type1-upload-container .Type1-upload-top-container {
    flex-direction: column;
  }
}

.Type1-upload-container .Type1-upload-top-container .titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
  width: 50%;
}

@media (max-width: 640px) {
  .Type1-upload-container .Type1-upload-top-container .titleInput {
    width: 80%;
    font-size: 1.0rem;
    border-radius: 5px;
    padding: 12px 20px;
  }
}

.Type1-upload-container .Type1-upload-body-container {
  width: 100%;
  margin-top: 40px;
}

@media (max-width: 640px) {
  .Type1-upload-container .Type1-upload-body-container {
    margin-top: 10px;
  }
}

.Type1-upload-container .Type1-upload-body-container .type1Text {
  height: 200px;
  width: 80%;
  padding: 20px 20px 20px 20px;
  border-radius: 14px;
  border-style: solid;
  background-color: #d7d7d8;
  border-color: #fcf9f9;
}

.Type1-upload-container .Type1-upload-body-container .Type1-upload-word-counter {
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-left: 10px;
  color: #808080;
}

.Type1-upload-container .Type1-upload-tools-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .Type1-upload-container .Type1-upload-tools-container {
    margin-top: 10px;
  }
}

.Type1-upload-container .Type1-upload-tools-container .Type1-upload-tools-button {
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  background-color: white;
  border-style: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.Type1-upload-container .Type1-upload-tools-container .delete {
  color: red;
}

.Type2-upload-container {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .Type2-upload-container {
    padding: 10px;
    border-radius: 7px;
  }
}

.Type2-upload-container .Type2-upload-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-top-container {
    flex-direction: column;
  }
}

.Type2-upload-container .Type2-upload-top-container .titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
  width: 50%;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-top-container .titleInput {
    width: 80%;
    font-size: 1.0rem;
    border-radius: 5px;
    padding: 12px 20px;
  }
}

.Type2-upload-container .Type2-upload-body-container {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-body-container {
    margin-top: 10px;
    flex-direction: column;
  }
}

.Type2-upload-container .Type2-upload-body-container .Type2-textContainer {
  max-height: 100%;
  width: 47%;
  display: flex;
  align-items: left;
  justify-content: start;
  padding: 20px 20px 20px 20px;
  padding-top: 0px;
  margin: 0px;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-body-container .Type2-textContainer {
    width: 100%;
    padding: 0px;
  }
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer {
  max-height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-body-container .Type2-imageContainer {
    width: 100%;
  }
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imagePreview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imagePreview .Type2-img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imageTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imageTools {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imageTools .Type2-radio-labels {
  font-weight: 700;
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imageTools .Type2-uploadImageLabel {
  font-family: "Alegreya Sans", sans-serif;
  text-transform: lowercase;
  color: #ffb71b;
  background-color: #010000;
  padding: 5px 20px 5px 20px;
}

.Type2-upload-container .Type2-upload-body-container .Type2-imageContainer .Type2-imageTools .Type2-deleteImage-button {
  cursor: pointer;
  background-color: transparent;
  color: #333330;
  border-style: none;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
}

.Type2-upload-container .Type2-upload-word-counter {
  width: 98%;
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-right: 40px;
  margin-left: 40px;
  color: #808080;
  text-align: left;
}

.Type2-upload-container .Type2-highlighted-container {
  margin-left: 0px;
  width: 100%;
}

.Type2-upload-container .Type2-highlighted-container .Type2-highlighted-text {
  width: 98%;
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-right: 40px;
  margin-left: 10px;
  color: #808080;
  text-align: left;
}

.Type2-upload-container .Type2-upload-tools-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .Type2-upload-container .Type2-upload-tools-container {
    margin-top: 40px;
  }
}

.Type2-upload-container .Type2-upload-tools-container .Type2-upload-tools-button {
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  background-color: white;
  border-style: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.Type2-upload-container .Type2-upload-tools-container .delete {
  color: red;
}

.Type3-upload-container {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .Type3-upload-container {
    border-radius: 7px;
    padding: 10px;
  }
}

.Type3-upload-container .Type3-title-container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-title-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.Type3-upload-container .Type3-title-container .Type3-title-titleInput-container {
  width: 50vw;
}

@media (max-width: 1200px) {
  .Type3-upload-container .Type3-title-container .Type3-title-titleInput-container {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-title-container .Type3-title-titleInput-container {
    width: 100%;
    margin-bottom: 5px;
  }
}

.Type3-upload-container .Type3-title-container .Type3-title-titleInput-container .Type3-titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
  margin-left: 10px;
}

@media (max-width: 1200px) {
  .Type3-upload-container .Type3-title-container .Type3-title-titleInput-container .Type3-titleInput {
    width: 70%;
    border-radius: 5px;
    font-size: 1.0rem;
  }
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-title-container .Type3-title-titleInput-container .Type3-titleInput {
    width: 80%;
    font-size: 1.0rem;
    border-radius: 5px;
    padding: 12px 20px;
    margin-left: 0px;
  }
}

.Type3-upload-container .Type3-body-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Type3-upload-container .Type3-body-container .Type3-body-imageContainer {
  max-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-body-imagePreview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-body-imagePreview {
    border-radius: 5px;
  }
}

.Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-body-imagePreview .Type3-body-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-body-imagePreview .Type3-body-img {
    border-radius: 5px;
  }
}

.Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-imageTools {
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}

.Type3-upload-container .Type3-body-container .Type3-body-imageContainer .Type3-imageTools .Type3-deleteImage-button {
  cursor: pointer;
  background-color: transparent;
  color: #333330;
  border-style: none;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  margin-right: 0px;
}

.Type3-upload-container .Type3-highlighted-container {
  margin-left: 0px;
  width: 100%;
}

.Type3-upload-container .Type3-highlighted-container .Type3-highlighted-text {
  width: 98%;
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-right: 40px;
  margin-left: 10px;
  color: #808080;
  text-align: left;
}

.Type3-upload-container .Type3-upload-tools-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .Type3-upload-container .Type3-upload-tools-container {
    margin-top: 10px;
  }
}

.Type3-upload-container .Type3-upload-tools-container .Type3-upload-tools-button {
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  background-color: white;
  border-style: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.Type3-upload-container .Type3-upload-tools-container .delete {
  color: red;
}

.Type4-upload-container {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .Type4-upload-container {
    border-radius: 7px;
    padding: 10px;
  }
}

.Type4-upload-container .Type4-upload-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Type4-upload-container .Type4-upload-top-container .titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
  width: 50%;
}

@media (max-width: 640px) {
  .Type4-upload-container .Type4-upload-top-container .titleInput {
    width: 100%;
    font-size: 1.0rem;
    border-radius: 5px;
    padding: 12px 20px;
  }
}

.Type4-upload-container .Type4-body-container {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 640px) {
  .Type4-upload-container .Type4-body-container {
    margin-top: 10px;
  }
}

.Type4-upload-container .Type4-body-container .Type4-body-imagePreview {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .Type4-upload-container .Type4-body-container .Type4-body-imagePreview {
    border-radius: 5px;
  }
}

.Type4-upload-container .Type4-body-container .Type4-body-imagePreview .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Type4-upload-container .Type4-body-container .Type4-body-imagePreview .img {
    border-radius: 5px;
  }
}

.Type4-upload-container .Type4-body-container .Type4-imageTools {
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}

.Type4-upload-container .Type4-body-container .Type4-imageTools .Type4-deleteImage-button {
  cursor: pointer;
  background-color: transparent;
  color: #333330;
  border-style: none;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  margin-right: 0px;
}

.Type4-upload-container .Type4-highlighted-container {
  margin-left: 0px;
  width: 100%;
}

.Type4-upload-container .Type4-highlighted-container .Type4-highlighted-text {
  width: 98%;
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-right: 40px;
  margin-left: 10px;
  color: #808080;
  text-align: left;
}

.Type4-upload-container .Type4-upload-tools-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .Type4-upload-container .Type4-upload-tools-container {
    margin-top: 10px;
  }
}

.Type4-upload-container .Type4-upload-tools-container .Type4-upload-tools-button {
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  background-color: white;
  border-style: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.Type4-upload-container .Type4-upload-tools-container .delete {
  color: red;
}

.Type5-upload-container {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .Type5-upload-container {
    border-radius: 7px;
    padding: 10px;
  }
}

.Type5-upload-container .Type5-body-container {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container {
    background-color: #17aa4a;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.Type5-upload-container .Type5-body-container > * {
  background-color: #f8f8f8;
}

.Type5-upload-container .Type5-body-container .group {
  width: 100%;
  height: 624px;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .group {
    height: 400px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .group {
    height: 200px;
  }
}

.Type5-upload-container .Type5-body-container .group .imageContainer {
  height: 312px;
  border-style: dashed;
  border-width: 1px;
  border-color: lightblue;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .group .imageContainer {
    height: 200px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .group .imageContainer {
    height: 100px;
  }
}

.Type5-upload-container .Type5-body-container .group .imageContainer .img {
  width: 100%;
  height: 312px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .group .imageContainer .img {
    height: 200px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .group .imageContainer .img {
    height: 100px;
  }
}

.Type5-upload-container .Type5-body-container .group .colo {
  display: grid;
  grid-template-columns: 70% 30%;
  height: 312px;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .group .colo {
    height: 200px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .group .colo {
    height: 100px;
  }
}

.Type5-upload-container .Type5-body-container .group .colo2 {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 312px;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .group .colo2 {
    height: 200px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .group .colo2 {
    height: 100px;
  }
}

.Type5-upload-container .Type5-body-container .side {
  width: 100%;
  height: 624px;
  border-style: dashed;
  border-width: 1px;
  border-color: lightblue;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .side {
    height: 400px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .side {
    height: 200px;
  }
}

.Type5-upload-container .Type5-body-container .side .colo {
  height: 100%;
  width: 100%;
}

.Type5-upload-container .Type5-body-container .side .colo .imageContainer {
  width: 100%;
}

.Type5-upload-container .Type5-body-container .side .colo .imageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Type5-upload-container .Type5-body-container .side .colo .imageContainer .imagePreview .img {
  width: 100%;
  height: 624px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .side .colo .imageContainer .imagePreview .img {
    height: 400px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .side .colo .imageContainer .imagePreview .img {
    height: 200px;
  }
}

.Type5-upload-container .Type5-body-container .side .colo .imageContainer .uploadImageLabel {
  height: 624px;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .side .colo .imageContainer .uploadImageLabel {
    height: 400px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .side .colo .imageContainer .uploadImageLabel {
    height: 200px;
  }
}

.Type5-upload-container .Type5-body-container .imageContainer {
  width: 100%;
}

.Type5-upload-container .Type5-body-container .imageContainer .imagePreview {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Type5-upload-container .Type5-body-container .imageContainer .imagePreview .img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.Type5-upload-container .Type5-body-container .imageContainer .imageTools {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Type5-upload-container .Type5-body-container .imageContainer .imageTools .uploadImageLabel {
  height: 312px;
  font-family: "Alegreya Sans", sans-serif;
  text-transform: lowercase;
  color: #ffb71b;
  padding: 5px 20px 5px 20px;
}

@media (max-width: 1200px) {
  .Type5-upload-container .Type5-body-container .imageContainer .imageTools .uploadImageLabel {
    height: 200px;
  }
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-body-container .imageContainer .imageTools .uploadImageLabel {
    height: 100px;
  }
}

.Type5-upload-container .Type5-highlighted-container {
  margin-left: 0px;
  width: 100%;
}

.Type5-upload-container .Type5-highlighted-container .Type5-highlighted-text {
  width: 98%;
  font-weight: 800;
  margin-top: 10px;
  font-size: 0.86rem;
  margin-right: 40px;
  margin-left: 10px;
  color: #808080;
  text-align: left;
}

.Type5-upload-container .Type5-upload-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Type5-upload-container .Type5-upload-top-container .titleInput {
  background-color: ghostwhite;
  border-style: none;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Alegreya Sans", sans-serif;
  width: 50%;
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-upload-top-container .titleInput {
    width: 100%;
    border-radius: 5px;
    font-size: 1.0rem;
  }
}

.Type5-upload-container .Type5-upload-tools-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .Type5-upload-container .Type5-upload-tools-container {
    margin-top: 0px;
  }
}

.Type5-upload-container .Type5-upload-tools-container .Type5-upload-tools-button {
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  background-color: white;
  border-style: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.Type5-upload-container .Type5-upload-tools-container .delete {
  color: red;
}
/*# sourceMappingURL=UploadBlogStyles.css.map */
.UploadBudget-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UploadBudget-container .UploadBudget-title-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-title-container {
    width: 90%;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-title-container {
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 90%;
  }
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.86rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title p {
  padding: 0;
  margin: 0;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-title-container .UploadBudget-title {
    font-size: 1.86rem;
  }
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons {
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons .UploadBudget-title-back {
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #164734;
  width: 80px;
  height: 35px;
  transition: 0.3s;
  border: solid 2px #164734;
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons .UploadBudget-title-back:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons .UploadBudget-title-save {
  margin-left: 20px;
  background-color: #164734;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  color: #ffb71b;
  width: 80px;
  height: 35px;
}

.UploadBudget-container .UploadBudget-title-container .UploadBudget-title-buttons .UploadBudget-title-save:hover {
  background-color: #ffb71b;
  color: #164734;
  border: solid 2px #164734;
}

.UploadBudget-container .UploadBudget-info-labels-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-info-labels-container {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container {
    width: 100%;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 800;
  font-size: 0.76rem;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels {
    width: 60%;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-text {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-text {
    margin-left: 35px;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-text {
    display: none;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-arrows {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 0.86rem;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-arrows {
    font-size: 0.76rem;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-difference {
  color: #E04E4E;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-info-labels .UploadBudget-info-labels-difference {
    font-size: 0.6rem;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  margin-right: 40px;
  font-weight: 800;
  text-align: right;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels {
    width: 40%;
    margin-right: 0px;
    font-size: 0.76rem;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels .UploadBudget-meta-expected-label {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels .UploadBudget-meta-expected-label {
    font-size: 0.86rem;
  }
}

.UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels .UploadBudget-meta-final-label {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels .UploadBudget-meta-final-label {
    font-size: 0.86rem;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-info-labels-container .UploadBudget-meta-labels .UploadBudget-meta-final-label {
    margin-right: 20px;
  }
}

.UploadBudget-container .UploadBudget-budget-box {
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  width: 80%;
  height: 650px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-budget-box {
    width: 100%;
    box-shadow: none;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box {
    width: 100%;
    box-shadow: none;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  margin-right: 35px;
  margin-left: 35px;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-total-label {
  font-size: 1.2rem;
  font-weight: 700;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.76rem;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-categories {
  width: 25%;
  height: 100%;
  font-weight: 700;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-categories {
    width: 100%;
    font-size: 0.6rem;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-categories .UploadBudget-cell {
  height: 6.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-categories .UploadBudget-cell p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 5px;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional1 {
  display: block;
  width: 25%;
  height: 100%;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional1 {
    display: none;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional1 .UploadBudget-cell {
  height: 6.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional1 .UploadBudget-cell p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional1 .UploadBudget-cell .UploadBudget-cell-textfield {
  height: 100%;
  width: 100%;
  border-style: none;
  padding-left: 5px;
  background: white;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional2 {
  display: block;
  width: 25%;
  height: 100%;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional2 {
    display: none;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional2 .UploadBudget-cell {
  height: 6.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional2 .UploadBudget-cell p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional2 .UploadBudget-cell .UploadBudget-cell-textfield {
  height: 100%;
  width: 100%;
  border-style: none;
  padding-left: 5px;
  background: white;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional3 {
  display: block;
  width: 25%;
  height: 100%;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional3 {
    display: none;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional3 .UploadBudget-cell {
  height: 6.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  /*
              border-style: solid;
              border-width: 1px;
              border-color: rgb(159, 159, 159);
              border-radius: 2px;
              */
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional3 .UploadBudget-cell p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-left .UploadBudget-breakdown-container .UploadBudget-optional3 .UploadBudget-cell .UploadBudget-cell-textfield {
  height: 100%;
  width: 100%;
  border-style: none;
  padding: 0;
  padding-left: 5px;
  /*
                border-style: solid;
                border-width: 1px;
                border-color: rgb(159, 159, 159);
                border-radius: 2px;
                */
  background: white;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right {
    width: 50%;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-total-label {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: right;
  width: 85%;
  border-style: solid;
  border-width: 1px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-image: linear-gradient(to right, white, #bdbdbd) 1;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-total-label {
    font-size: 0.86rem;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-total-label {
    font-size: 0.86rem;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-xe {
  font-size: 1.2rem;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-xe {
    font-size: 0.86rem;
  }
}

@media (max-width: 640px) {
  .UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-value-container .UploadBudget-xe {
    font-size: 0.86rem;
  }
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-breakdown-container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.76rem;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-breakdown-container .UploadBudget-cell {
  height: 6.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-breakdown-container .UploadBudget-cell p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.UploadBudget-container .UploadBudget-budget-box .UploadBudget-buffer-box .UploadBudget-box-right .UploadBudget-box-right-column .UploadBudget-breakdown-container .UploadBudget-cell .UploadBudget-cell-textfield {
  height: 100%;
  width: 92%;
  border-style: none;
  padding-right: 5px;
  background: white;
  text-align: right;
  border-style: solid;
  border-width: 1px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-image: linear-gradient(to right, white, #bdbdbd) 1;
}
/*# sourceMappingURL=UploadBudgetStyles.css.map */
.CountryPicker-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding-right: 20px;
  color: #ffb71b;
  border-radius: 14px;
}

.CountryPicker-container .CountryPicker {
  background-color: #f8f8f8;
  height: 60px;
  border-style: none;
  text-transform: capitalize;
  padding-left: 20px;
  font-size: 1rem;
  border-radius: 14px;
  -webkit-appearance: none;
  cursor: pointer;
}
/*# sourceMappingURL=CountryPicker.css.map */
.Account-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Account-container .Account-sidebar-container {
  width: 20vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .Account-container .Account-sidebar-container {
    display: none;
  }
}

.Account-container .Account-sidebar-container .Account-sidebar-title {
  margin-left: 20px;
  width: 62%;
  margin-top: 20vh;
  font-size: 1.36rem;
  word-wrap: break-word;
  font-weight: 800;
  text-align: left;
  color: #333330;
  line-height: 130%;
}

.Account-container .Account-sidebar-container .Account-sidebar-link {
  width: 45%;
  height: 40px;
  border-radius: 7px;
  margin-left: 20px;
  text-decoration: none;
  cursor: pointer;
}

.Account-container .Account-sidebar-container .Account-sidebar-link .Account-sidebar-button {
  width: 100%;
  height: 100%;
  background-color: #164734;
  color: #ffb71b;
  font-size: 0.7rem;
  font-weight: 800;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-style: none;
  border-radius: 7px;
  padding: 15px;
  cursor: pointer;
}

.Account-container .Account-content-container {
  width: 80vw;
  margin-right: 5%;
}

@media (max-width: 1200px) {
  .Account-container .Account-content-container {
    margin-right: 0%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 290px) {
  .Account-container .Account-content-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.Account-container .Account-content-container .Account-channel-name {
  width: 100%;
  margin-top: 5vh;
  font-size: 2.56rem;
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-channel-name {
    font-size: 1.86rem;
  }
}

@media (max-width: 290px) {
  .Account-container .Account-content-container .Account-channel-name {
    font-size: 1rem;
  }
}

.Account-container .Account-content-container .Account-profile-container {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-profile-container {
    height: auto;
  }
}

.Account-container .Account-content-container .Account-profile-container .Account-profile-image {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 2px;
  border-color: #333330;
}

@media (max-width: 290px) {
  .Account-container .Account-content-container .Account-profile-container .Account-profile-image {
    width: 50px;
    height: 50px;
  }
}

.Account-container .Account-content-container .Account-profile-container .Account-name-container {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  text-align: left;
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-profile-container .Account-name-container {
    margin-left: 10px;
  }
}

.Account-container .Account-content-container .Account-profile-container .Account-name-container .Account-name {
  font-size: 1.6rem;
  color: #333330;
  margin: 0;
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-profile-container .Account-name-container .Account-name {
    font-size: 1rem;
  }
}

.Account-container .Account-content-container .Account-profile-container .Account-name-container .Account-email {
  font-size: 1.0rem;
  color: #333330;
  margin: 0;
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-profile-container .Account-name-container .Account-email {
    font-size: 0.6rem;
  }
}

.Account-container .Account-content-container .Account-profile-container .Account-name-container .Account-disclaimer {
  margin-top: 5px;
  font-size: 0.5rem;
  color: #a8a8a8;
  font-weight: 700;
  padding: 0px;
}

.Account-container .Account-content-container .Account-channelDescription {
  width: 95%;
  text-align: justify;
  margin-top: 10px;
  font-size: 1.06rem;
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .Account-container .Account-content-container .Account-channelDescription {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .Account-container .Account-content-container .Account-channelDescription {
    font-size: 0.86rem;
    width: 100%;
  }
}

@media (max-width: 325px) {
  .Account-container .Account-content-container .Account-channelDescription {
    font-size: 0.6rem;
    width: 80%;
  }
}

@media (max-width: 290px) {
  .Account-container .Account-content-container .Account-channelDescription {
    width: 95%;
    font-size: 0.6rem;
  }
}

.Account-container .Account-content-container .Account-trips-label {
  font-size: 1.86em;
  word-wrap: break-word;
  text-align: left;
  font-weight: 800;
  margin-right: auto;
  margin-top: 20px;
}

.Account-container .Account-content-container .Account-trips {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 80%;
}

@media (max-width: 1200px) {
  .Account-container .Account-content-container .Account-trips {
    width: 100%;
  }
}
/*# sourceMappingURL=Account.css.map */
.SignIn-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.SignIn-container .SignIn-background-image-container {
  width: 70%;
  height: 100%;
  display: block;
  background-color: #333330;
}

.SignIn-container .SignIn-background-image-container .SignIn-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #333330;
}

@media (max-width: 950px) {
  .SignIn-container .SignIn-background-image-container {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .SignIn-container .SignIn-background-image-container {
    display: none;
  }
}

.SignIn-container .SignIn-sidebar-container {
  width: 30%;
  height: 100%;
  box-shadow: 30px 0px 30px rgba(0, 0, 0, 0.05);
  font-weight: 800;
  z-index: 1;
  text-align: left;
}

@media (max-width: 950px) {
  .SignIn-container .SignIn-sidebar-container {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .SignIn-container .SignIn-sidebar-container {
    width: 100%;
  }
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-label {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 80px;
  font-size: 1.86rem;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-label2 {
  margin-left: 40px;
  width: 70%;
  font-size: 1.26rem;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container .SignIn-sidebar-profile-image {
  width: 20%;
  aspect-ratio: 1/1;
  border-style: solid;
  border-color: black;
  background-color: #333330;
  border-width: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container .SignIn-sidebar-profile-name {
  margin-top: 30px;
  font-size: 1.26rem;
  text-transform: uppercase;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container .SignIn-sidebar-button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container .SignIn-sidebar-button-div .SignIn-sidebar-logout-button {
  margin-top: 40px;
  border-style: none;
  width: 200px;
  height: 44px;
  background-color: #F8F8F8;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  color: #333330;
  font-size: 0.86rem;
  font-weight: 800;
  border-radius: 6px;
  border: solid 1px #333330;
  cursor: pointer;
  transition: 0.3s;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedIn-container .SignIn-sidebar-button-div .SignIn-sidebar-logout-button:hover {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedOut-container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedOut-container .SignIn-sidebar-button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedOut-container .SignIn-sidebar-button-div .SignIn-sidebar-login-button {
  margin-top: 100px;
  border-style: none;
  width: 50%;
  height: 44px;
  background-color: #F8F8F8;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #333330;
  font-size: 0.86rem;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.SignIn-container .SignIn-sidebar-container .SignIn-sidebar-loggedOut-container .SignIn-sidebar-button-div .SignIn-motivation-label {
  margin-top: 15px;
  font-weight: 400;
  font-size: 0.86rem;
}

.ChannelSettings-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
}

@media (max-width: 640px) {
  .ChannelSettings-container {
    flex-direction: column;
  }
}

.ChannelSettings-container .ChannelSettings-sidebar {
  width: 30%;
}

@media (max-width: 640px) {
  .ChannelSettings-container .ChannelSettings-sidebar {
    width: 100%;
  }
}

.ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container {
  width: 100%;
  margin-top: 10vh;
}

@media (max-width: 640px) {
  .ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
  }
}

.ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container .ChannelSettings-sidebar-button {
  width: 65%;
  height: 40px;
  background-color: #F8F8F8;
  border-style: none;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: 800;
  color: #333330;
  transition: 0.3s;
  cursor: pointer;
}

.ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container .ChannelSettings-sidebar-button:hover {
  background-color: #ffb71b;
  color: #164734;
}

@media (max-width: 640px) {
  .ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container .ChannelSettings-sidebar-button {
    width: 40%;
    margin-right: 10px;
  }
}

.ChannelSettings-container .ChannelSettings-sidebar .ChannelSettings-sidebar-buttons-container .ChannelSettings-sidebar-button-selected {
  background-color: #164734;
  color: #ffb71b;
}

.ChannelSettings-container .ChannelSettings-body {
  width: 100%;
  height: auto;
  overflow: auto;
  /* code added */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.ChannelSettings-container .ChannelSettings-body .ChannelSettings-body-title-label {
  margin-top: 4vh;
  font-size: 1.86em;
  word-wrap: break-word;
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
  font-weight: 800;
}

.ChannelSettings-container .ChannelSettings-body .form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 40px;
  width: 80%;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-body-channel-image-container {
  width: 100%;
  height: auto;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-body-channel-image-container .ChannelSettings-change-image-label {
  color: #C1C1C1;
  font-size: 0.86em;
  text-align: left;
  font-weight: 800;
  padding: 0;
  margin: 0;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-body-channel-image-container .ChannelSettings-image-box {
  width: 100px;
  margin-top: 5px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-body-channel-image-container .ChannelSettings-image-box .ChannelSettings-image-input {
  background-color: #333330;
  width: 100px;
  height: 100px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-body-channel-image-container .ChannelSettings-image-box .ChannelSettings-image-label {
  width: 100px;
  height: 100px !important;
  object-fit: cover;
  cursor: pointer;
  background-color: #333330;
  border-style: solid;
  border-width: 3px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-field-label {
  padding: 0;
  margin: 0;
  color: #C1C1C1;
  font-size: 0.86em;
  text-align: left;
  font-weight: 800;
  margin-top: 20px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-warn-label {
  font-weight: 800;
  margin-top: 10px;
  color: #5a5a5a;
  font-size: 0.7em;
  text-transform: lowercase;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-field-input {
  height: 50px;
  width: 263px;
  background-color: #F8F8F8;
  border-style: none;
  border-radius: 14px;
  padding-left: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  margin-top: 5px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-field-dropper {
  border-style: none;
  height: 50px;
  margin-top: 5px;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-channel-description-textarea {
  color: #333330;
  margin-top: 10px;
  width: 60%;
}

@media (max-width: 640px) {
  .ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-channel-description-textarea {
    width: 100%;
  }
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-submit-button {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 150px;
  background-color: #164734;
  color: #ffb71b;
  border: solid 2px #164734;
  border-radius: 6px;
  font-weight: 800;
  transition: 0.3s;
  cursor: pointer;
}

.ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-submit-button:hover {
  background-color: #ffb71b;
  color: #164734;
}

@media (max-width: 640px) {
  .ChannelSettings-container .ChannelSettings-body .form .ChannelSettings-submit-button {
    margin-top: 5px;
  }
}

.ChannelSettings-container .ChannelSettings-body .ChannelSettings-trips {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 40px;
  width: 80%;
}

.ContactUs-container {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactUs-container .ContactUs-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 40vh;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  background-color: #ffb71b;
  text-decoration: none;
  cursor: pointer;
}

.ContactUs-container .ContactUs-box .ContactUs-Title {
  font-size: 2rem;
  color: #164734;
  text-transform: uppercase;
  text-decoration: underline;
}

.ContactUs-container .ContactUs-box .ContactUs-message {
  font-size: 1.4rem;
  color: #333330;
  font-weight: 600;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .ContactUs-container .ContactUs-box .ContactUs-message {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 640px) {
  .ContactUs-container .ContactUs-box .ContactUs-message {
    font-size: 0.86rem;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.ContactUs-container .ContactUs-box:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: #fbbb30;
}
/*# sourceMappingURL=Daxil.css.map */
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #164734;
  height: 150px;
}

.footer-container .footer-logo {
  font-size: 2rem;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-container .footer-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.footer-container .footer-links-container .footer-link {
  text-decoration: none;
  color: #ffb71b;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.87rem;
}
/*# sourceMappingURL=Footer.css.map */
.HardBlogList-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.HardBlogList-container .HardBlogList-sidebar {
  width: 20vw;
  height: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.HardBlogList-container .HardBlogList-sidebar .HardBlogList-sidebar-title {
  margin-left: 20px;
  width: 65%;
  margin-top: 20vh;
  font-size: 1.36rem;
  word-wrap: break-word;
  font-weight: 800;
  text-align: left;
  color: #333330;
  line-height: 130%;
}

.HardBlogList-container .HardBlogList-sidebar .HardBlogList-sidebar-link {
  width: 47%;
  height: 40px;
  border-radius: 7px;
  margin-left: 20px;
  text-decoration: none;
  cursor: pointer;
}

.HardBlogList-container .HardBlogList-sidebar .HardBlogList-sidebar-link .HardBlogList-sidebar-button {
  width: 100%;
  height: 100%;
  background-color: #164734;
  color: #ffb71b;
  font-size: 0.7rem;
  font-weight: 800;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-style: none;
  border-radius: 7px;
  padding: 15px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .HardBlogList-container .HardBlogList-sidebar {
    display: none;
  }
}

.HardBlogList-container .HardBlogList-body {
  margin-left: 0px;
  margin-right: 5%;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .HardBlogList-container .HardBlogList-body {
    margin-right: 40px;
    margin-left: 40px;
    width: 100vw;
  }
}

@media (max-width: 640px) {
  .HardBlogList-container .HardBlogList-body {
    margin-right: 20px;
    margin-left: 20px;
    width: 90vw;
  }
}

.HardBlogList-container .HardBlogList-body .HardBlogList-trips-label {
  font-size: 1.86em;
  word-wrap: break-word;
  width: 80%;
  text-align: left;
  font-weight: 800;
  margin-right: auto;
  margin-top: 10vh;
}

@media (max-width: 1200px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-trips-label {
    margin-top: 2vh;
  }
}

@media (max-width: 640px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-trips-label {
    margin-left: 2vw;
    width: 100%;
    margin-top: 3vh;
  }
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list {
  width: 80%;
}

@media (max-width: 1200px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-container-list {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-container-list {
    width: 100%;
  }
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item {
  width: 100%;
  height: 250px;
  border-radius: 14px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item {
    margin-bottom: 40px;
  }
}

@media (max-width: 640px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item {
    flex-direction: column;
    height: 100%;
    margin-bottom: 40px;
  }
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item .HardBlogList-container-item-image {
  min-width: 40%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px 0 0 14px;
}

@media (max-width: 640px) {
  .HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item .HardBlogList-container-item-image {
    width: 100%;
    border-radius: 14px 14px 0 0;
  }
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  color: #333330;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-title {
  margin: 0;
  font-size: 1.86em;
  word-wrap: break-word;
  width: 80%;
  text-align: left;
  font-weight: 800;
  margin-top: 20px;
  margin-left: 20px;
  text-transform: uppercase;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-author {
  margin: 0;
  font-size: 0.6em;
  word-wrap: break-word;
  width: 80%;
  text-align: left;
  font-weight: 800;
  margin-top: 5px;
  margin-left: 20px;
  text-transform: uppercase;
  color: #164734;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-places-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-places-container .HardBlogList-item-countries {
  margin: 0;
  font-size: 0.6em;
  word-wrap: break-word;
  text-align: left;
  font-weight: 800;
  color: gray;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-places-container .HardBlogList-item-cities {
  margin: 0;
  font-size: 0.6em;
  word-wrap: break-word;
  text-align: left;
  font-weight: 800;
  margin-left: 20px;
  color: gray;
}

.HardBlogList-container .HardBlogList-body .HardBlogList-container-list .HardBlogList-container-item a .HardBlogList-item-summary {
  margin: 0;
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: justify;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 400;
  font-size: 0.86rem;
}
/*# sourceMappingURL=HardBlogList.css.map */
.DetailedIssues {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.DetailedIssues .DetailedIssues-theme-design-container {
  width: 100%;
  height: 10vh;
  background-color: #473030;
}

.DetailedIssues .DetailedIssues-theme-design-container .DetailedIssues-theme-design-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.DetailedIssues .DetailedIssues-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main {
    flex-direction: column;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-left {
  width: 40%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-left {
    width: 100%;
    height: 77vh;
  }
}

@media (max-width: 640px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-left {
    height: 72vh;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-left .DetailedIssues-cover {
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-left .DetailedIssues-cover {
    margin-top: 3vh;
  }
}

@media (max-width: 640px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-left .DetailedIssues-cover {
    margin-top: 1vh;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-left .DetailedIssues-cover .DetailedIssues-cover-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right {
    width: 100%;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-date {
  margin: 0px;
  padding: 0px;
  margin-top: 7vh;
  font-size: 2.86rem;
  font-weight: 800;
  width: 100%;
  text-align: left;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-date {
    margin-left: 5vw;
    margin-top: 0vh;
  }
}

@media (max-width: 640px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-date {
    font-size: 1.86rem;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-mag {
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  font-size: 1.16rem;
  font-weight: 700;
  width: 100%;
  text-decoration: none;
  text-align: left;
  color: #333330;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-mag {
    margin-left: 5vw;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-navButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-navButtons {
    margin-left: 5vw;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-navButtons .DetailedIssues-navButtons-back {
  margin: 0;
  padding: 0;
  background-color: #333330;
  color: #ffb71b;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 0.76rem;
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-navButtons .DetailedIssues-navButtons-next {
  margin: 0;
  padding: 0;
  background-color: #164734;
  color: #ffb71b;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 0.76rem;
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-articles {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-articles {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-articles .DetailedIssues-article {
  height: 200px;
  width: 142px;
  aspect-ratio: 1/1.414;
  border-radius: 5px;
  background-color: #000000;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-articles .DetailedIssues-article .DetailedIssues-article-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-desc-header {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  font-size: 1.36rem;
  font-weight: 800;
  width: 100%;
  text-align: left;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-desc-header {
    margin-left: 5vw;
  }
}

.DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-desc {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  text-align: justify;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-main .DetailedIssues-right .DetailedIssues-desc {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.DetailedIssues .DetailedIssues-special-container {
  width: 100%;
  margin-bottom: 5vh;
}

.DetailedIssues .DetailedIssues-special-container .DetailedIssues-special-img {
  width: 80%;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .DetailedIssues .DetailedIssues-special-container .DetailedIssues-special-img {
    width: 90%;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (max-width: 640px) {
  .DetailedIssues .DetailedIssues-special-container .DetailedIssues-special-img {
    margin-left: 20px;
    margin-right: 20px;
  }
}
/*# sourceMappingURL=DetailedIssues.css.map */
.Piece-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #F2F2F2;
}

.Piece-container .Piece-header {
  width: 100%;
  height: 450px;
  background-color: #FFE2A2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Piece-container .Piece-header .Piece-header-title {
  width: 59%;
  font-size: 3.86rem;
  text-align: left;
  font-weight: 400;
  padding-left: 50px;
  margin-top: 45px;
}

.Piece-container .Piece-article-container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.Piece-container .Piece-article-container .Piece-article-meta-container {
  width: 100%;
  height: 180px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-top .Piece-header-date {
  font-size: 1rem;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  text-align: left;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-top .Piece-header-line {
  margin-left: 0;
  margin-right: 0;
  border: 1px solid darkgray;
  width: 85%;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-main .Piece-header-author {
  margin: 0;
  font-size: 2.86rem;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  text-align: left;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-main .Piece-share-container {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-main .Piece-share-container .Piece-share-label {
  font-size: 1.86rem;
  margin: 0px;
  margin-right: 20px;
}

@media (max-width: 640px) {
  .Piece-container .Piece-article-container .Piece-article-meta-container .Piece-article-meta-main .Piece-share-container .Piece-share-label {
    font-size: 1.4rem;
  }
}

.Piece-container .Piece-article-container .Piece-article-into {
  width: 100%;
  font-size: 1.86rem;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  text-align: justify;
  font-weight: 500;
  margin-top: 50px;
}

.Piece-container .Piece-article-container .Piece-article-into::first-letter {
  float: left;
  padding: 0 10px 0 0;
  letter-spacing: 10px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  font-size: 400%;
  margin-top: -0.2em;
  margin-bottom: -0.3em;
}

.Piece-container .Piece-article-container .Piece-article {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  font-size: 1.66rem;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 300;
  letter-spacing: 0.9px;
}
/*# sourceMappingURL=Piece.css.map */
